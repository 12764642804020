const compatibilityData = {
  "A-": {
    redCells: {
      compatible: ["A-", "O-"],
      caveats: [],
    },
    platelets: {
      compatible: ["A-", "AB-"],
      caveats: [
        {
          group: "O-",
          caveat: "$",
        },
        {
          group: "B-",
          caveat: "$",
        },
        {
          group: "O+",
          caveat: "$*",
        },
        {
          group: "A+",
          caveat: "*",
        },
        {
          group: "B+",
          caveat: "$*",
        },
        {
          group: "AB+",
          caveat: "*",
        },
      ],
    },
    plasma: {
      compatible: ["A-", "AB-", "A+", "AB+"],
      caveats: [
        {
          group: "B-",
          caveat: "%",
        },
        {
          group: "B+",
          caveat: "%",
        },
      ],
    },
  },
  "A+": {
    redCells: {
      compatible: ["A+", "O+", "A-", "O-"],
      caveats: [],
    },
    platelets: {
      compatible: ["A+", "AB+", "A-", "AB-"],
      caveats: [
        {
          group: "O-",
          caveat: "$",
        },
        {
          group: "B-",
          caveat: "$",
        },
        {
          group: "O+",
          caveat: "$",
        },
        {
          group: "B+",
          caveat: "$",
        },
      ],
    },
    plasma: {
      compatible: ["A+", "AB+", "A-", "AB-"],
      caveats: [
        {
          group: "B-",
          caveat: "%",
        },
        {
          group: "B+",
          caveat: "%",
        },
      ],
    },
  },
  "B-": {
    redCells: {
      compatible: ["B-", "O-"],
      caveats: [],
    },
    platelets: {
      compatible: ["B-", "AB-"],
      caveats: [
        {
          group: "O-",
          caveat: "$",
        },
        {
          group: "A-",
          caveat: "$",
        },
        {
          group: "O+",
          caveat: "$*",
        },
        {
          group: "A+",
          caveat: "$*",
        },
        {
          group: "B+",
          caveat: "*",
        },
        {
          group: "AB+",
          caveat: "*",
        },
      ],
    },
    plasma: {
      compatible: ["B-", "AB-", "B+", "AB+"],
      caveats: [
        {
          group: "A+",
          caveat: "%",
        },
        {
          group: "A-",
          caveat: "%",
        },
      ],
    },
  },
  "B+": {
    redCells: {
      compatible: ["B+", "O+", "B-", "O-"],
      caveats: [],
    },
    platelets: {
      compatible: ["B+", "AB+", "B-", "AB-"],
      caveats: [
        {
          group: "O-",
          caveat: "$",
        },
        {
          group: "A-",
          caveat: "$",
        },
        {
          group: "O+",
          caveat: "$",
        },
        {
          group: "A+",
          caveat: "$",
        },
      ],
    },
    plasma: {
      compatible: ["B+", "AB+", "B-", "AB-"],
      caveats: [
        {
          group: "A+",
          caveat: "%",
        },
        {
          group: "A-",
          caveat: "%",
        },
      ],
    },
  },
  "AB-": {
    redCells: {
      compatible: ["AB-", "O-", "A-", "B-"],
      caveats: [],
    },
    platelets: {
      compatible: ["AB-"],
      caveats: [
        {
          group: "AB+",
          caveat: "*",
        },
        {
          group: "O-",
          caveat: "$",
        },
        {
          group: "A-",
          caveat: "$",
        },
        {
          group: "B-",
          caveat: "$",
        },
        {
          group: "O+",
          caveat: "$*",
        },
        {
          group: "A+",
          caveat: "$*",
        },
        {
          group: "B+",
          caveat: "$*",
        },
      ],
    },
    plasma: {
      compatible: ["AB-", "AB+"],
      caveats: [
        {
          group: "A+",
          caveat: "%",
        },
        {
          group: "A-",
          caveat: "%",
        },
        {
          group: "B+",
          caveat: "%",
        },
        {
          group: "B-",
          caveat: "%",
        },
      ],
    },
  },
  "AB+": {
    redCells: {
      compatible: ["AB+", "O+", "A+", "B+", "AB-", "O-", "A-", "B-"],
      caveats: [],
    },
    platelets: {
      compatible: ["AB+", "AB-"],
      caveats: [
        {
          group: "O-",
          caveat: "$",
        },
        {
          group: "A-",
          caveat: "$",
        },
        {
          group: "B-",
          caveat: "$",
        },
        {
          group: "O+",
          caveat: "$",
        },
        {
          group: "A+",
          caveat: "$",
        },
        {
          group: "B+",
          caveat: "$",
        },
      ],
    },
    plasma: {
      compatible: ["AB+", "AB-"],
      caveats: [
        {
          group: "A+",
          caveat: "%",
        },
        {
          group: "A-",
          caveat: "%",
        },
        {
          group: "B+",
          caveat: "%",
        },
        {
          group: "B-",
          caveat: "%",
        },
      ],
    },
  },
  "O-": {
    redCells: {
      compatible: ["O-"],
      caveats: [],
    },
    platelets: {
      compatible: ["O-", "A-", "B-", "AB-"],
      caveats: [
        {
          group: "O+",
          caveat: "*",
        },
        {
          group: "A+",
          caveat: "*",
        },
        {
          group: "B+",
          caveat: "*",
        },
        {
          group: "AB+",
          caveat: "*",
        },
      ],
    },
    plasma: {
      compatible: ["O-", "A-", "B-", "AB-", "O+", "A+", "B+", "AB+"],
      caveats: [],
    },
  },
  "O+": {
    redCells: {
      compatible: ["O+", "O-"],
      caveats: [],
    },
    platelets: {
      compatible: ["O+", "A+", "B+", "AB+", "O-", "A-", "B-", "AB-"],
      caveats: [],
    },
    plasma: {
      compatible: ["O+", "A+", "B+", "AB+", "O-", "A-", "B-", "AB-"],
      caveats: [],
    },
  },
}

export default compatibilityData
