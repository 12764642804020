import React from "react"
import { css } from "@emotion/core"
import { theme } from "../styles/theme"

const BloodGroupResultTile = ({ colour, group, itemKey, caveats }) => {
  return (
    <li css={styles.container} key={itemKey}>
      <div
        css={[
          styles.tile,
          css({
            color: theme.colours[colour],
            borderColor: theme.colours[colour],
          }),
        ]}
      >
        <h3 css={styles.title}>{group}</h3>
        {caveats && <p css={styles.caveats}>{caveats}</p>}
      </div>
    </li>
  )
}

const styles = {
  container: css({
    paddingLeft: theme.gapHalf,
    paddingRight: theme.gapHalf,
  }),
  tile: css({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: theme.borderRadius,
    height: 180,
    width: 180,
    fontWeight: "bold",
    border: "4px solid",
  }),
  title: css({
    fontSize: theme.fzH1,
  }),
  caveats: css({
    position: "absolute",
    bottom: theme.gap,
    paddingRight: theme.gap,
    left: 0,
    width: "100%",
    fontSize: "1rem",
    textAlign: "right",
  }),
}

export default BloodGroupResultTile
