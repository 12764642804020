import React from "react"
import { Redirect } from "@reach/router"
import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"
import BloodGroupResultTile from "../../components/BloodGroupResultTile"

import { theme } from "../../styles/theme"

import compatibilityData from "../../data/compatibilityData"
import { Link } from "gatsby"

const Results = ({ location }) => {
  if (!location.state) {
    return <Redirect to="/compatibility/" noThrow />
  }

  const { state = { group: null, component: null } } = location
  const { group, component } = state

  if (!group || !component) {
    return <Redirect to="/compatibility/" noThrow />
  }

  const groupResults = compatibilityData[group][component]
  const compatibleGroupsItems = groupResults.compatible.map(group => {
    return (
      <BloodGroupResultTile
        colour={
          component === "redCells"
            ? "red"
            : component === "platelets"
            ? "blue"
            : "yellow"
        }
        group={group}
        itemKey={group}
        key={group}
      />
    )
  })

  const caveatsGroupsItems = groupResults.caveats.length
    ? groupResults.caveats.map(bloodGroupCaveatItem => {
        return (
          <BloodGroupResultTile
            colour={
              component === "redCells"
                ? "red"
                : component === "platelets"
                ? "blue"
                : "yellow"
            }
            group={bloodGroupCaveatItem.group}
            itemKey={group}
            caveats={bloodGroupCaveatItem.caveat}
          />
        )
      })
    : null

  return (
    <Layout>
      <SEO title="Blood Components Compatibility" />

      <CustomNavTitle
        title="Blood Components Compatibility"
        destination="/compatibility/component-choice"
        location={location}
      />

      <article className="mt-gap-2" css={theme.components.row}>
        <header css={styles.header}>
          <p css={theme.components.bloodGroupIcon}>{group}</p>
          <h2 css={theme.components.title}>
            Compatible{" "}
            {component === "redCells"
              ? "red cells"
              : component === "plasma"
              ? "FFP / Cryo"
              : component}
          </h2>
        </header>

        <ul css={styles.list}>{compatibleGroupsItems}</ul>

        {caveatsGroupsItems && (
          <section css={styles.caveatsSection}>
            <div css={styles.header}>
              <p css={theme.components.bloodGroupIcon}>{group}</p>
              <h2 css={theme.components.title}>
                The following may be suitable following discussion with your
                transfusion laboratory staff.
              </h2>
            </div>

            <ul css={styles.list}>{caveatsGroupsItems}</ul>

            <Link
              to="/compatibility/caveats"
              css={styles.link}
              state={{ group, component }}
            >
              Read More
            </Link>
          </section>
        )}

        <Link to="/compatibility" css={theme.components.button}>
          Start Again
        </Link>
      </article>
    </Layout>
  )
}

const styles = {
  header: css({
    color: theme.colours.black,
    marginBottom: theme.gap,
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.gap,
  }),
  list: css({
    listStyle: "none",
    marginBottom: theme.gap,
    "&> *": {
      display: "inline-block",
      marginBottom: theme.gap,
      "@media (max-width: 620px)": {
        width: "auto",
      },
    },
  }),
  caveatsSection: css({
    borderTop: `2px solid ${theme.colours.lightGrey}`,
    paddingTop: theme.gapDouble,
  }),
  link: css({
    color: theme.colours.red,
    fontWeight: "bold",
    display: "inline-block",
    textDecoration: "underline",
    marginTop: theme.gap,
    marginBottom: theme.gapDouble,
    paddingLeft: theme.gapHalf,
    "&:hover, &:focus": {
      textDecoration: "none",
    },
  }),
}

export default Results
